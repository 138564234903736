<template>
  <div class="tableAdminTeacher">
    <a-modal @cancel="closeModalSchedule" :title="`Schedule for ${dataGuruSchedule.nama}`" centered :footer="null" :visible="scheduleTeacherIsVisible" :width="1200">
      <BoxScheduleAdmin :idTahunAjaran="idTahunAjaran" v-if="!loadingDataSchedule && modelSchedule" :modalVisible="scheduleTeacherIsVisible" :model="modelSchedule" :dataGuru='dataGuruSchedule' :slots="allSlot" @teacherSlotUpdate="teacherSlotUpdate" />
    </a-modal>
    <!-- <div>
      <div class="cui__utils__heading mb-0">
        <strong>Rules</strong>
      </div>
      <div
        class="text-muted mb-3"
      >On this page you can view, search, add, edit, change password, and delete teachers</div>
      <hr />
    </div> -->
    <div :class="[!isMobile ? 'd-flex' : '', !isMobile ? 'flex-nowrap' : '']">
      <div class="d-flex">
        <a-form-item>
          <a-input
            v-model="search"
            @keyup="searchTeacher"
            placeholder="Search by NIK or Nama.."
            :style="{
              width: isMobile ? '100%' : '225px',
              height: '40px'
            }"
          ></a-input>
        </a-form-item>
        <div class="ml-3">
          <a-select @change="handleChangeFilter" v-model="selectRole" size="large">
            <a-select-option value="">All Data</a-select-option>
            <a-select-option v-for="role in roles" :key="role.key" :value="role.tag">{{role.name}}</a-select-option>
          </a-select>
        </div>
      </div>
      <!-- <div :class="['ml-auto', isMobile ? 'mb-4' : '']">
        <a-button :block="isMobile" @click="toAddTeacher" type="primary" icon="plus" style="height:40px">Add Teacher</a-button>
      </div> -->
    </div>
    <a-table
      :columns="columns"
      :data-source="dataTable"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <!-- <span slot="Nama" slot-scope="text, record"><a class="linkHover" href="" @click.prevent="toDetailTeacher(record.key)">{{text}}</a></span> -->
      <span slot="Nama" slot-scope="text, record"><a class="linkHover" href="" @click.prevent="toScheduleTeacher(record.key)">{{text}}</a></span>
      <span slot="Nik" slot-scope="text">{{text ? text : '-'}}</span>
      <span slot="Role" slot-scope="text, record">
        <template v-for="(role, i) in record.Role">
          <a-tag class="my-2" color="blue" v-if="findRole(role, record.Role)" :key="i">{{findRole(role, record.Role).name}}</a-tag>
        </template>
      </span>
      <!-- <span slot="Actions" slot-scope="text, record">
        <a @click.prevent="toDetailTeacher(record.key)"
          :class="['btn', 'btn-outline-success', 'mx-2', isMobile ? 'd-block' : '', isMobile ? 'mb-2' : '']">
          Detail
        </a>
        <a
          @click.prevent="toScheduleTeacher(record.key)"
          class="btn btn-outline-primary mx-2"
        >Schedule</a>
      </span> -->
    </a-table>
  </div>
</template>

<script>
import { roles } from '@/helpers/roles.js'
// import moment from 'moment'
const BoxScheduleAdmin = () => import('@/components/app/Admin/BoxScheduleAdmin')
const columns = [
  {
    title: 'NIK',
    dataIndex: 'Nik',
    // width: '15%',
    scopedSlots: {
      customRender: 'Nik',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Nama',
    dataIndex: 'Nama',
    sorter: true,
    scopedSlots: { customRender: 'Nama' },
    // width: '32%',
  },
  {
    title: 'Role',
    dataIndex: 'Role',
    scopedSlots: { customRender: 'Role' },
    // width: '32%',
  },
  // {
  //   title: 'Actions',
  //   dataIndex: 'Actions',
  //   align: 'center',
  //   // width: '20%',
  //   scopedSlots: { customRender: 'Actions' },
  // },
]
export default {
  data() {
    return {
      search: '',
      columns,
      dataTable: [],
      pagination: {},
      loading: false,
      roles,
      selectRole: '',
      dataGuruSchedule: {
        nama: '',
        id: null,
      },
      scheduleTeacherIsVisible: false,
      allSlot: [],
      loadingDataSchedule: false,
      modelSchedule: null,
    }
  },
  props: ['idTahunAjaran'],
  components: { BoxScheduleAdmin },
  methods: {
    handleChangeFilter() {
      this.fetchData({ search: this.search })
    },
    findRole(roleData, allRole) {
      const data = this.roles.find(role => { return roleData === role.tag })
      // console.log(data)
      const newData = data ? { ...data } : null
      if (newData?.children) {
        // console.log(newData?.children, 'masuk')
        const children = newData.children.find(role => { return allRole.includes(role.tag) })
        // console.log(children, newData, allRole)
        if (children) newData.name += (' Bag. ' + children.name)
      }
      return newData
    },
    toAddTeacher() {
      this.$router.push({ name: 'Add Teacher Admin' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Teacher Admin'],
      })
    },
    toDetailTeacher(id) {
      this.$router.push({ name: 'Detail Teacher Admin', params: { id } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Teacher Admin'],
      })
    },
    // toScheduleTeacher(id) {
    //   this.$router.push({ name: 'Schedule Teacher Admin', params: { id } })
    //   this.$store.commit('menu/SET_STATE', {
    //     currentMenu: ['Teacher Admin'],
    //   })
    // },
    toScheduleTeacher(id) {
      this.dataGuruSchedule.id = id
      this.loadingDataSchedule = true
      this.fetchDataSchedule(id)
      this.scheduleTeacherIsVisible = true
      // console.log(id)
    },
    searchTeacher() {
      if (this.search.length > 2) {
        this.fetchData({
          search: this.search,
        })
      } else {
        this.fetchData()
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      let order = ''
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchData({
        page: pagination.current,
        order: order,
      })
    },
    fetchData(params = { page: 1, order: 'ASC', search: '' }) {
      this.loading = true
      this.$store.dispatch('admin/FETCH_TEACHERS', { page: params.page, order: params.order, search: params.search, role: this.selectRole })
        .then(data => {
          // console.log(data)
          const pagination = { ...this.pagination }
          pagination.total = data.total
          this.loading = false
          this.dataTable = data.guru.map(row => {
            const isPrincipal = Boolean(row.periode_manajemen_atas.length)
            const roles = JSON.parse(row.role)
            if (isPrincipal) roles.push('kepalaSekolah')
            return {
              key: row.id,
              Nik: row.NIK,
              Nama: row.nama,
              Role: roles,
            }
          })
          this.pagination = pagination
        })
    },
    teacherSlotUpdate(data) {
      this.allSlot = data.allSlot
    },
    async fetchDataSchedule(idGuru) {
      try {
        const { namaGuru, allSlot } = await this.$store.dispatch('admin/FETCH_SCHEDULE_TEACHER', { idGuru, idTahunAjaran: this.idTahunAjaran })
        this.dataGuruSchedule.nama = namaGuru
        this.allSlot = allSlot
        this.loadingDataSchedule = false
      } catch (err) {
        console.log(err)
      }
    },
    closeModalSchedule() {
      this.idGuruSchedule = null
      this.scheduleTeacherIsVisible = false
      this.dataGuruSchedule.nama = ''
    },
    async fetchModelActiveSchedule() {
      try {
        const data = await this.$store.dispatch('slot/FETCH_MODEL_ACTIVE_SCHEDULE')
        this.modelSchedule = data
      } catch (err) {
        console.log(err)
      }
    },
  },
  created() {
    // this.fetchDataSchedule()
    this.fetchData()
    this.fetchModelActiveSchedule()
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang = 'scss'>
.tableAdminTeacher {
  .linkHover:hover {
    text-decoration: underline;
  }
  .btn.btn-outline-danger,
  .show > .btn.btn-outline-danger {
    border-color: #f56a79 !important;
    color: white !important;
    color: #f56a79 !important;
  }

  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
    table {
      @media (max-width: 769px) {
        width: auto;
      }
    }
  }

  .btn.btn-outline-danger:hover,
  .btn.btn-outline-danger:active,
  .show > .btn.btn-outline-danger:hover,
  .show > .btn.btn-outline-danger:active {
    background-color: #f56a79 !important;
    color: white !important;
    border-color: #f56a79 !important;
  }

  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .show > .btn-outline-danger.dropdown-toggle {
    background-color: #f56a79 !important;
    color: white !important;
    border-color: #f56a79 !important;
  }
}
</style>
